<template>
  <div class="notification">
    <sdPopover placement="bottomLeft" action="click">
      <template v-slot:content>
        <AtbdTopDropdwon class="atbd-top-dropdwon">
          <sdHeading as="h5" class="atbd-top-dropdwon__title">
            <span class="title-text">Notifikasi</span>
            <a-badge class="badge-success" :count="notification.count" />
          </sdHeading>
          <perfect-scrollbar
            :options="{
              wheelSpeed: 1,
              swipeEasing: true,
              suppressScrollX: true,
            }"
          >
            <ul class="atbd-top-dropdwon__nav notification-list">
              <li v-for="item in notification.notifications" :key="item.id" @click="readHandler(item.id)">
                <!-- click send to read -->
                <router-link :to="item.url">
                  <div class="atbd-top-dropdwon__content notifications">
                    <!-- <div class="notification-icon bg-primary">
                      <sdFeatherIcons type="hard-drive" />
                    </div> -->
                    <div class="notification-content d-flex">
                      <div class="notification-text">
                        <!-- <sdHeading as="h5"> <span>James</span> sent you a message </sdHeading> -->
                        <sdHeading as="h5" v-html="item.message" />
                        <p>{{ item.user_from }} | {{ item.time }}</p>
                      </div>
                      <div class="notification-status" v-if="item.read_at === null">
                        <a-badge dot />
                      </div>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </perfect-scrollbar>
          <router-link class="btn-seeAll" to="/notifications">
            Lihat Semua Notifikasi
          </router-link>
        </AtbdTopDropdwon>
      </template>
      <a-badge v-if="notification.count > 0" dot :offset="[-8, -5]">
        <a to="#" class="head-example">
          <sdFeatherIcons type="bell" size="20" />
        </a>
      </a-badge>
      <a-badge v-else :offset="[-8, -5]">
        <a to="#" class="head-example">
          <sdFeatherIcons type="bell" size="20" />
        </a>
      </a-badge>
    </sdPopover>
  </div>
</template>
<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { AtbdTopDropdwon } from './auth-info-style';
import { reactive, onMounted } from 'vue';
import { DataService } from '@/config/dataService/dataService';
// import { getItem } from '@/utility/localStorageControl';

export default {
  name: 'Notification',
  components: {
    AtbdTopDropdwon,
    PerfectScrollbar,
  },
  setup() {
    const notification = reactive({
      notifications: [],
      count: 0,
    });
    onMounted(() => {
      // setInterval(() => {
      //   if (getItem('access_token'))
      //     DataService.get('notification-unread/').then(response => {
      //       notification.notifications = response.data.notifications;
      //       notification.count = response.data.count;
      //     });
      // }, 10000);
    });

    const readHandler = id => {
      DataService.get(`read-notification/${id}`).then(response => {
        notification.notifications = response.data.notifications;
        notification.count = response.data.count;
      });
    };

    return { notification, readHandler };
  },
};
</script>
<style scoped>
.ps {
  height: 200px;
}
</style>
